<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <h2>เติมเครดิต</h2>
          <b-form
            class="mt-10"
            @submit.prevent
          >
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="แนบสลิปลูกค้า"
                  label-for="mc-first-name"
                >
                  <b-form-file
                    ref="topup-slip"
                    placeholder="แนบสลิปลูกค้า"
                    drop-placeholder="ลากสลิปมาใส่ที่นี่"
                    accept="image/jpeg, image/png, image/gif"
                    @change="uploadSlipImage($event)"
                  />
                  <img
                    id="slipImage"
                    :src="topupInfo.slipImage"
                    style="width:50%;height:50%"
                  >
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="topup"
                >
                  เติมเครดิต
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="clearTopup"
                >
                  ยกเลิก
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script src="https://unpkg.com/html5-qrcode"></script>
<script>
import Vue from 'vue'
Vue.component('qrcode-scanner', {
  props: {
    qrbox: {
      type: Number,
      default: 250,
    },
    fps: {
      type: Number,
      default: 10,
    },
  },
  mounted() {
    const config = {
      fps: this.fps,
      qrbox: this.qrbox,
    }
    const html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', config)
    html5QrcodeScanner.render(this.onScanSuccess)
  },
  template: '<div id="qr-code-full-region"></div>',
  methods: {
    onScanSuccess(decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult)
    },
  },
})

// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BFormFile, BTable, BBadge, BFormGroup, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BFormTimepicker, BCardText, BImg, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormFile,
    BCardText,
    BFormCheckbox,
    BFormCheckboxGroup,
    // BImg,
    Cleave,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormTimepicker,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      slipImage: false,
      pageOptions: [100, 200, 500, 1000],
      bankaccounts: [],
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      topupInfo: {
      },
      withdrawInfo: {
        amount: 0.0,
        reason: '',
      },
      deductInfo: {
        amount: 0.0,
        reason: '',
      },
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
      ],
      /* eslint-disable global-require */
      items: [
      ],
      memberInfo: null,
      labels: {
        th: {
          labelHours: 'ชั่วโมง',
          labelMinutes: 'นาที',
          labelSeconds: 'วินาที',
          labelIncrement: 'เพิ่ม',
          labelDecrement: 'ลด',
          labelSelected: 'เลือก',
          labelNoTimeSelected: 'เลือกเวลา',
        },
      },
      uploadedSlip: [],
      /* eslint-disable global-require */
    }
  },
  computed: {
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter(f => f.sortable)
    //     .map(f => ({ text: f.label, value: f.key }))
    // },
    more_turn_amount() {
      return this.topupInfo.more_turn_amount || 0
    },
  },
  watch: {
    more_turn_amount(newVal) {
      this.topupInfo.withthdrawable_turn_amount = parseFloat((this.topupInfo.more_turn_amount || '').replace(/,/ig, '')) + parseFloat(this.memberInfo.total_turn_amount)
    },
    // topupInfo: {
    //   handler(newVal, _) {
    //     console.log('watch:topupInfo', newVal, _)
    //     if ((newVal.turnlimit || []).length < 1) {
    //       delete this.topupInfo.more_turn_amount
    //     } else {
    //       this.topupInfo.withthdrawable_turn_amount = parseFloat((this.topupInfo.more_turn_amount || '').replace(/,/ig, '')) + parseFloat(this.memberInfo.total_turn_amount)
    //     }
    //   },
    //   deep: true,
    // },
  //   'topupInfo.more_turn_amount': newVal => {
  //     this.topupInfo.withthdrawable_turn_amount = parseFloat(newVal) + parseFloat(this.topupInfo.amount)
  //   },
  },
  mounted() {
    this.totalRows = this.items.length
    if (this.$route.params.user !== null && this.$route.params.user !== undefined)
      this.tableQuery.phoneno = this.$route.params.user
    this.fetchRecords()
    this.fetchBankAccounts()
  },
  methods: {
    onScan(a, b) {
      console.log('onScan', a, b)
    },
    calculateWithdrawTurnAmount() {
      console.log('calculateWithdrawTurnAmount')
      this.topupInfo.withthdrawable_turn_amount = parseFloat(this.topupInfo.more_turn_amount) + parseFloat(this.topupInfo.amount)
    },
    searchMember() {
      this.fetchRecords()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.memberInfo = null
    },
    clearTopup() {
      this.topupInfo = {
      }
    },
    async fetchBankAccounts() {
      const { data: _bankaccounts } = await this.$http.get('bankaccounts')
      this.bankaccounts = _bankaccounts.bankaccounts.data.map(i => ({ ...i, title: `${i.bank} ${i.acc_no} ${i.acc_name}` })).filter(bank => ['IN', 'IN-OUT'].indexOf(bank.acc_type) >= 0)
      this.bankaccounts.push({
        title: 'ทรูมันนี่', acc_no: 'tmw', bank: 'tmw', acc_name: 'ทรูมันนี่วอลเล็ท',
      })
      this.bankaccounts.push({
        title: 'อื่นๆ', acc_no: 'other', bank: 'other', acc_name: 'อื่นๆ',
      })
      // this.bankaccounts
    },
    async fetchRecords() {
      if (this.tableQuery.phoneno === null || this.tableQuery.phoneno === undefined) {
        this.items = []
        return this.items
      }

      let fieldName = 'phoneno'
      if (/[a-zA-Z]/.test(this.tableQuery.phoneno))
        fieldName = 'username'

      const { data: memberApiResult } = await this.$http.get(`/users/user-info?with_deposits=N&searchBy=${fieldName}&${fieldName}=${this.tableQuery.phoneno}&user=${this.tableQuery.phoneno}`)
      this.memberInfo = memberApiResult.user
      // this.items = _wallettx.message.data
      // this.totalRows = _wallettx.message.total
      return this.items
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.tableQuery.page = 1
    },
    async uploadSlipImage(event) {
      this.slipImage = false
      const fileReader = new FileReader()
      const ctx = this
      fileReader.onload = () => {
        console.log(fileReader.result)
        ctx.topupInfo.slipImage = fileReader.result
        document.getElementById('slipImage').src = fileReader.result
      }
      fileReader.readAsDataURL(event.target.files[0])
      await this.delay(3)
      this.$forceUpdate()
      // console.log('delay 3 secs')
      // this.slipImage = true
    },
    async delay(x) {
      return new Promise(resolve => {
        setTimeout(() => resolve(true), x * 1000)
      })
    },
    async topup() {
      //
      const errorTitle = 'เติมเครดิต'
      if (this.topupInfo.slipImage === undefined || this.topupInfo.slipImage == null) {
        this.$swal({
          title: errorTitle,
          text: 'กรุณาอัพสลิปจากลูกค้า',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'ปิด',
          customClass: {
            confirmButton: 'btn btn-info',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      const ctx = this
      this.$http.post('topup/slip-verification', { ...ctx.topupInfo }, { validateStatus: status => !!status })
      .then(result => {
        if (result.data.success) {
          ctx.$bvToast.toast(`อัพโหลดสลิปเรียบร้อย`, {
            variant: 'success',
            title: 'ระบบกำลงตรวจสลิปและเติมเงินให้ลูกค้า',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
          })
          ctx.clearTopup()
          this.uploadedSlip.push(result.data)
          return
        }
        ctx.$bvToast.toast(`อัพโหลดสลิปไม่สำเร็จ เนื่องจาก ${result.data.error_message}`, {
          variant: 'danger',
          title: 'อัพโหลดสลิป',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
